.lg-table-ex-wrap{
	margin-bottom:5px;
	.pop-menu{
		overflow: hidden; 
		box-shadow: 0px 0px 8px 3px #ccc;
		border-radius:3px;
		padding:5px 0 5px 0;
		position: absolute;
		background-color:#fff;
		z-index:1000000;
		display:none;
		text-align:left;
		min-width:120px;
		font-family: raleway,century gothic,texgyreadventor,sans-serif;

	  .menu-item{
	    padding:0px 20px 0px 10px;
	    font-size:12px;
	    letter-spacing:2px;
	  }

	  .menu-text{
	    font-size:13px;  
	  }

	  .menu-item:hover{
	    background-color:rgba(24, 144, 255, 0.03);
	  }
	}

	.lg-table-ex{
		.editable{
			cursor:text;
		}

		.ant-table-column-title{
			font-size:14px;
		}

		.editnot{
			cursor:default;
			text-align: left;
		}

		.edit-input{
			font-size:12px;
			letter-spacing:1px;
			min-width:120px;
		}

		.lg-table-row{
			td{
				font-size:12px;
				color:#666;

				a{
					font-size:12px;
					color:#1890ff;
				}

				a:hover{
					color:#1890ff;
				}

				span{
					font-size:12px;
					color:#666;
				}
			}
		}
	}

	.lg-table-total{
		letter-spacing:2px;
		margin-right:5px;
		font-size:12px;
	}
}