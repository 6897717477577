body {
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Source Han Sans", "Noto Sans CJK Sc", "Microsoft YaHei", "Microsoft Jhenghei", sans-serif;
  background-color: #f9f9f9;
  color: rgba(0,0,0,.65);
}

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  width: 100% !important;
  margin: 0 !important;
}

#root {
  height: 100%;
  overflow: hidden;
}

.page-container {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.page-logo-content {
  width: 180px;
  height: 64px;
  line-height: 64px;
  margin-left: 0%;
  overflow:hidden;
  display:inline-block;
  background-color:#ffff00;

  img {
    display: inline-block;
    height: 32px;
    vertical-align: middle;
  }

  span {
    color: #000;
    display: inline-block;
    margin: 0 0 0 5px;
    font-weight: 400;
    font-size: 18px;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap;
  }
}
