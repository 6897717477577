.header-menu {
  flex: 1 1 0%;
  overflow: hidden;

  .ant-menu-submenu {
        background:none !important;;
    }

  .ant-menu-submenu-title {
        background:none !important;;
    }

}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-dropdown-menu-item {
  font-weight: 400;
  min-width: 120px;
}
.head-menus-right {
  min-width: 191px;
  margin-right:0%;
  display: flex;
  justify-content: flex-end;
  float:right;
  
  height:100%;
  .header-link {
    padding-right: 15px;
    cursor: pointer;
    color: #314659;
    a {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
    &:hover {
      color: #1890ff;
      //background: rgba(0,0,0,.025);
    }

    .pub-btn{
      background-color:#ffff00;
      padding:5px 10px 5px 10px;

      border-radius:5px;
    }
  }
  .avatar-area {
    padding-left: 15px;
    cursor: pointer;
    .user-avatar {
      margin-right: 0px;
      vertical-align: middle;
      border:2px solid #eee;
    }
    .user-name {
      margin-left:5px;
      vertical-align: middle;
      color: #314659!important;
    }


  }
}

.resume-img{
    width:20px;
    height:25px;
    padding:0px 0 0 0;
    position:relative;
  }

  .black{
    fill:rgb(59, 58, 58);
    stroke: rgb(59, 58, 58);
  }

  