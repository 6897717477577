
.main-page{
  border-bottom:1px dashed #ddd;
  background-color:#fff;
  position:relative;
  overflow:hidden;
  display:flex;
  text-algin:center;
  font-family: raleway,century gothic,texgyreadventor,sans-serif;

  .middle{
    margin:0px 0 0 0;
    display: -webkit-box;
    -webkit-box-orient: horizontal;
    display:flex;
    width: 100%;
    position:absolute; 
    z-index:1;
    left:0;
    top:50%;
    left:50%;
    transform: translate3d(-50%, -50%, 0);
    width:100%;
    padding:10px;
    text-align:center;

    .hmiddle{
      margin: 0 auto;
      display: inline-block;

      //font-family: raleway,century gothic,texgyreadventor,sans-serif;
      line-height:22px;
    }

    .lg-title{
      font-size: 48px;
      font-weight: 500;
      line-height: 30px;
      margin: 0 0 30px;
      padding: 0 30px;
      text-align: center;
    }

    .lg-desc{
      
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 6px;
      text-transform: uppercase;
      color: #999;
      text-align: center;
      

        span{
          display:inline-block;

        }
    }

    .lg-cursor{
      opacity: 1;
      display:inline-block;
      -webkit-animation: blink .7s infinite;
      -moz-animation: blink .7s infinite;
      animation: blink .7s infinite;
    }
  }

  .click-down{
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #4d4d4d;
    cursor: pointer;
    height: 32px;
    font-size: 18px;

    background:-moz-linear-gradient(top,rgb(255,255,255),rgb(250,250,250));
    background:-ms-linear-gradient(top,rgb(255,255,255),rgb(250,250,250));
    background:-o-linear-gradient(top,rgb(255,255,255),rgb(250,250,250));
    background:-webkit-linear-gradient(top,rgb(255,255,255),rgb(250,250,250));
  }

  .click-down:hover{
    cursor: pointer;
    background:-moz-linear-gradient(top,rgb(255,255,255),rgb(240,240,240));
    background:-ms-linear-gradient(top,rgb(255,255,255),rgb(240,240,240));
    background:-o-linear-gradient(top,rgb(255,255,255),rgb(240,240,240));
    background:-webkit-linear-gradient(top,rgb(255,255,255),rgb(240,240,240));
  }

  .desc-left{
    margin:100px 0;
    flex:1;
    text-align:left;
    overflow:hidden;

    .desc-content{
      margin-left:15%;
      
      h3 {
        font-weight: 500; 
        font-size:30px;
        margin:30px 0 30px 0;
      }

      p {
          font-size: 16px;
          line-height: 28px;
          margin: 20px 0 40px 0;
          color:#777;
          letter-spacing: 2px;
      }

      h4 {
          font-size: 18px;
          font-weight: 400;
          margin:20px 0 20px 0;
          color: #344048;
          letter-spacing:2px;

          -webkit-animation: slide-in 1s forwards;
          animation: slide-in 1s forwards;

          img{
            width:16px; height:16px;
            margin:-3px 5px 0 0;
          }

          .icon{
            margin:-3px 5px 0 0;
          }
      }

      

    }
  }

  .desc-right{
    margin:170px 0;
    flex:1;
    text-align:left;
    position:relative;

    .desc-vedio-panel{
      position:absolute;
      display: inline-block;
      width:100%;
      height:100%;
      left:20px;
      top:-800px;

      .video{
        border-radius: 8px;
        box-shadow: 0px 0px 8px 3px #ccc;
        max-width: 600px;
        height: auto;
        width: 100%;
      }

    }
  }

  .milestone{
    width:100%;
    text-align:center;
    overflow:hidden;
    margin:40px 0;
  }

  .milestone-title{
    margin:20px 0 50px 0;
    width:100%;
    line-height:30px;

    h2{
      font-weight:500;
      font-size:30px;
      margin:40px 0 40px 0;
      letter-spacing: 2px;
    }

    span{
      letter-spacing:2px;
      font-family: raleway,century gothic,texgyreadventor,sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 6px;
      text-transform: uppercase;
      color: #999;
      text-align: center;
    }
  }

  .milestone-desc{
    display:flex;
    margin: 0 auto;
    width:80%;
    justify-content:center;

    li{
      flex:1; 
      margin:10px 10px 10px 10px; padding:10px; 
      overflow:hidden;
      display:block;
      text-align: left;
    }

    .m-cell-img{
      width: 300px;
      height: 100px;
      overflow:hidden;
      border-radius:2px;
      box-shadow: 0px 0px 8px 3px #ccc;
      img{
        width: 100%;
        height: 100%;
        height: auto;
        width: 100%;
      }
    }

    .m-cell-title{
      color: #546673;
      font-size: 16px;
      line-height: 36px;
      min-width: 120px;
      
      margin-top:5px;
    }

    .m-cell-desc{
      font-size: 12px;
      line-height: 1.45em;
      color: #888;
    }
  }


}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-animation: pulse 1.5s infinite;
    animation: pulse 1.5s infinite;
    font-size: 28px;
    
    color:black;
    position:absolute;
    transform:scale(0.7);
}

.down-animate{
  left: 0;
  top:0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position:absolute;
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

.animate-bounce-down{ 
    margin:100px auto;
    -webkit-animation: bounce-down 1.5s linear infinite;
    animation: bounce-down 1.5s linear infinite;
}

@-webkit-keyframes bounce-down {
 25% {-webkit-transform: translateY(-30px);}
 50%, 100% {-webkit-transform: translateY(0);}
 75% {-webkit-transform: translateY(5px);}
}

@keyframes bounce-down {
 25% {transform: translateY(-30px);}
 50%, 100% {transform: translateY(0);}
 75% {transform: translateY(5px);}
}

@-webkit-keyframes pulse{
  0%,to{-ms-filter:"alpha(opacity=40)";filter:alpha(opacity=40);opacity:.4;top:0}
  50%{-ms-filter:none;-webkit-filter:none;filter:none;opacity:1;top:-10px}
}

@keyframes pulse{
  0%,to{-ms-filter:"alpha(opacity=40)";filter:alpha(opacity=40);opacity:.4;top:0}
  50%{-ms-filter:none;-webkit-filter:none;filter:none;opacity:1;top:-16px}
}


@keyframes blink{0%{opacity:1}50%{opacity:0}100%{opacity:1}}
@-webkit-keyframes blink{0%{opacity:1}50%{opacity:0}100%{opacity:1}}
@-moz-keyframes blink{0%{opacity:1}50%{opacity:0}100%{opacity:1}}


.slide-show {
   position: absolute;
   left: 30em;
   width: 30em;
   height: 20em;
   background-color:#ffff00;
   -webkit-animation: slide-in 1s forwards;
   animation: slide-in 1s forwards;
}


@-webkit-keyframes slide-in {
  100% { left: 0 }
}
@keyframes slide-in {
  100% { left: 0 }
}


.animation-slide-down{
  -webkit-animation: slide-down 2s forwards;
  animation: slide-down 2s forwards;
}

@-webkit-keyframes slide-down {
  100% { top: 0 }
}
@keyframes slide-down {
  100% { top: 0 }
}

.lg-detail{
  font-weight:400;
}

.dot{
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display:inline-block;
  margin:0 5px 0 5px 0;

  .circle {
    vertical-align: middle;
  }

  .loading-circle {
    -webkit-animation: morph 2s ease infinite;
    animation: morph 2s ease infinite;
    vertical-align: middle;
  }

  @keyframes morph {
    0% {
      stroke-width: 4;
      stroke-opacity: 0.1;
    }
    25% {
      stroke-width: 8;
      stroke-opacity: 0.3;
    }
    50% {
      stroke-width: 4;
      stroke-opacity: 0.5;
    }
    75% {
      stroke-width: 4;
      stroke-opacity: 0.3;
    }
    100% {
      stroke-width: 4;
      stroke-opacity: 0.0;
    }
  }
}

.black{
  fill:rgb(59, 58, 58);
  stroke: rgb(59, 58, 58);
}

  .gray{
	  fill:rgb(117, 111, 111);
	  stroke: rgb(117, 111, 111);
  }

  .water-blue{
    fill:rgb(112, 182, 240);
	  stroke:rgb(112, 182, 240);
  }

  .blue{
    fill:rgb(12, 169, 241);
	  stroke:rgb(12, 169, 241);
  }

  .green{
    fill:rgb(65, 231, 93);
    stroke:rgb(65, 231, 93);
  }

.yellow{
	fill:rgb(191, 202, 34);
	stroke:rgb(186, 197, 29);
}

.pink{
	fill:rgb(226, 26, 199);
	stroke:rgb(226, 26, 199);
}

.red{
	fill:rgb(206, 59, 22);
	stroke:rgb(206, 59, 22);
}

.empty-info-large{
  text-align: center;
  vertical-align: middle;
  height: 200px;
  cursor: default;
  overflow: hidden;

  .ant-icon{
      color: #999;
  }

  span{
      cursor: default;
      display: inline-block;
      margin: 100px 0 0 5px;
      font-size:13px;
      letter-spacing: 2px;
      color: #999;
  }
}

.leave-head{
  margin:90px 0 0 0;
}
.tag-info{
  margin:0 0 0 8px;
}

.user-name{
  margin:3px 0 0 5px;
}

.new-milestone{
  border:dashed 1px;
  font-size:12px;
  span{
    font-size:13px;
  }
}

.pc-title{
	text-align:right;
	width:140px;
	font-size:14px;
	font-weight:400;
	padding-right: 10px;
}

.pc-detail{
	margin:10px 0 0 0;
	border:1px solid #eee;
	border-radius:3px;
	padding:10px;
	line-height:25px;
	width:460px;
	max-width:460px;
	min-height:50px;
	float:left;
	outline: 0 none;
	resize:both;
	border-color: rgba(82, 168, 236, 0.8);
	font-size: 13px;
}

.pc-detail:hover{
	border-bottom:2px solid rgba(82, 168, 236, 0.8);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
}


.ant-select-selection-selected-value{
  .lg-tag{
    display: inline;
    background-color: rgb(12, 169, 241);
    border-radius: 5px;
    padding:3px 10px 3px 10px;
  
    color: #fff;
    margin:0 10px 0 0;
    span{
      margin:0 0 0 8px;
    }
  }

  span{
    font-size: 13px;
  }
}

.lg-tag{
  display: inline;
  background-color: rgb(12, 169, 241);
  border-radius: 5px;
  padding:3px 10px 3px 10px;

  color: #fff;
  margin:0 10px 0 0;
  span{
    margin:0 0 0 8px;
  }
}

.tag-info{
  margin:0 0 0 8px;
}

.ant-select-dropdown-menu-item{
	span{
		font-size:13px;
		margin:0 0 0 3px;
	}
}

.ant-select-tree-title{
	font-size:13px;
}

.ant-select-tree-title{
	font-size:13px;
}

.ant-select-selection-selected-value{
	font-size:13px !important;
}


.ant-select-search__field{
	font-size:13px;
}

.ant-select-search__field__mirror{
	font-size:13px;
}

.ant-select-selection-selected-value{
	font-size:13px;
	font-weight:400;
}

.ant-menu-item{
	.ant-icon{
		vertical-align: middle;
		margin:-3px 8px 0 0;
	}
}

.link-style-on{
	margin:0 5px 0 3px;
}

.link-style-on:hover{
	cursor: pointer;
	color: #1890ff !important;
	span{
		color: #1890ff !important;
		cursor: pointer;
	}

	.ant-icon{
		color: #1890ff !important;
	}
}



.tip-show{
	font-size: 12px;
	max-width: 500px;
	line-height: 22px;
	p{
		font-size: 12px;
		line-height: 22px;
		letter-spacing: 1px;
		img{
			max-width: 80%;
			height: auto;
		}
	}
}
