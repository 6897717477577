.search-input{
    width: 300px;
}


.lg-search-ex-wrap{
    .item-empty{
        color:#999;
    }
}

.lg-search-ex-drop{
    .item-empty{
        color:#999;
    }

    .ant-input{
        font-size:13px;
        letter-spacing: 2px;
    }

    .anticon{
        vertical-align:middle;
        margin:3px 0 0 0;
    }
}

.ant-dropdown-menu-item{
    font-size:13px;
    span{
        font-size:13px;
        letter-spacing: 1px;
    }
}