.ant-layout-header {
  background-color: #fff;
  padding: 0;
  .ant-pro-top-nav-header {
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 64px;
    box-shadow: 0 2px 8px rgba(0,0,0,.15);
    transition: background .3s,width .2s;
    .ant-pro-top-nav-header-main {
      padding: 0 50px;
      margin: auto;
      display: flex;
      height: 64px;
      justify-content: space-between;
    }
  }
}
