.register-body{
  overflow:hidden;
	
}

.sep-main{
	display: flex;
    display: -webkit-flex;
    /* 从左端开始沿水平轴防止flex item */
    flex-direction: row;
    /* 强制 flex item不换行，沿着同一行堆叠 */
    flex-wrap: nowrap;
    /* flex item在主轴上的对齐方式，这里定义左对齐 */
    justify-content: flex-start;
    /* 定义交叉轴对其方式 */
    align-items: flex-start;

    line-height:20px;

    .sep-content{
    	margin:0 5px 0 5px;
    	display: inline-block;
    }

    .sep{
		height:2px;
		border:1px solid #eee;
		display:block;
		width:30%;
		background-color:#ffff00;
		line-height:20px;
		margin:8px 10px 8px 10px; 

	}
}




.login-style-main 
{
      width: 368px;
      margin: 0 auto;

      .user-protocol{
      	text-align:left;
      	margin: 0 2px 0px 10px;
      	letter-spacing:1px;
      	span{
      		font-size:13px;
      		margin-left:5px;
      	}

      	a{
      		font-size:13px;
      	}

      	img{
      		display: inline-block;
		    height: 20px;
		    vertical-align: middle;
      	}

      	.ant-ico{
      		color: rgba(0,0,0,1);
      	}
      }

      .more-logins{
      	display: flex;
      	margin:20px 0px 0px 0;
      	.login-type{
      		flex:1;
          cursor: pointer;
      	}
      }

      .login-index 
      {
        .ant-form 
        {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          color: rgba(0,0,0,1);
          font-size: 14px;
          line-height: 1.5;
          list-style: none;
          .login-tabs 
          {
            .login-way 
            {
              padding: 30px 16px;
              font-weight: 500;
              text-align: left;
            }
          }

          .input-area 
          {
            .input-line 
            {
              margin: 0 2px 24px;
              .ant-input-affix-wrapper 
              {
                height: 40px;
                .ant-input 
                {
                  font-size: 14px;
                  margin-left:5px;
                  width:99%;
                }
              }
              .ant-btn 
              {
                height: 40px;
                list-style: 40px;
              }
            }
          }
          .login-btn-row 
          {
            margin: 0 2px 24px;
            .login-btn 
            {
              width: 100%;
              margin-top: 24px;
              height: 40px;
              line-height: 40px;
            }
          }
        }
    }
}


// platform create css

.panel-title{
  margin:10px 0 0 0;
  line-height:32px;
  width:400px;
  text-align:left;

  h1{
    font-size:18px;
    text-align:left;
  }

  .panel-title-info{
    margin:10px 0 10px 0;
    font-size:13px;
    line-height:20px;
    letter-spacing:1px;
    color:#666;
    .pti-line{
      font-size:13px;
      overflow:hidden;
      line-height:23px;

      .line-ell{
        float:left;
        font-size:13px;
      }

      .line-elli{
        max-width:180px;
        font-size:18px;
        color:#333;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        float:left;
        margin:-2px 3px 0 3px;
        cursor:hand;
      }
    }
  }

}

.panel-platform-choose{
  display:flex;

  .ppc-one{
    flex:1;
    cursor:pointer;
    border:2px dashed #aaa;
    min-width:120px;
    min-height:100px;
    margin:10px;
    text-align:center;
    line-height:100px;
    border-radius: 5px; /*圆角*/
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;

    span{
      margin:0 0 0 5px;
    }
  }

  .small-code-input{
    height:80px;
    min-height:50px;
    line-height:50px;
  }

  .ppc-one:hover{
    border:2px dashed rgba(36,150,255,0.95);
    background-color:rgba(36,150,255,0.05);
  }

  .active{
    border:2px dashed rgba(36,150,255,0.95);
    background-color:rgba(36,150,255,0.05);
  }

  .one-join{
    line-height:32px;
  }

  .ant-input-affix-wrapper{
    .ant-input {
        border: none;
        border-bottom: 0px solid white !important;
        box-shadow: none;
        font-size:16px;
        background:none;
    }
  }

  .lg-input-none{
    width:0;height:0;float:left;visibility:hidden
  }

}

.lg-join-panel{
  .lg-join-count{
    text-align:left;
    font-size:13px;
    letter-spacing:1px;
    color:#333;
    span{
      font-size:20px;
      font-weight:600;
      margin:0 5px 0 5px;
      letter-spacing:2px;
    }
  }

  .lg-join-show{
      width:100%;
      height:100px;
      overflow:hidden;
  }

  .lg-join-user-panel{
    position:relative;
    left: 0;
    top:50%;
    max-width:80%;
    height:40px;
    line-height:30px;
    text-align:center;
    overflow:hidden;
    background-color:#fff;
    box-shadow: 5px 5px 8px rgba(0,0,0,0.5);
    transform: translate(0, -50%);
    border-radius: 3px; /*圆角*/
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    padding:5px;
  }
}

.show{
    opacity: 1;
    display:block;
    transition: all 1s ease-in ;
}

.hide{
    opacity: 0;
    display:none !important;
    transition: all 1s ease-in ;
}

.ant-tooltip-placement-top{
  .ant-tooltip-inner{
    background-color:rgba(255,255,255,0.8);
    color:#666;
  }

  .ant-tooltip-arrow{
  }
}

.lg-join-user-avatars{
  
  overflow:hidden;
  background-color:#ffff00;
  width:100%;

  .avatar-img{
    display:block;
    position:relative;
    position:absolute;
    float:left;
    margin:5px;
    cursor:pointer;
    left: 30px;
    bottom:8px;
  }

  .avatar-img:hover{
    width:50px;
    height:50px;
    bottom:8px;
  }
}






